import Typography, { TypographyProps } from '@mui/material/Typography';
import {} from '@mui/material';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<TypographyProps> & {
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  component?: React.ElementType; // https://github.com/mui/material-ui/issues/19512
};

const TextContained = ({
  color = 'primary',
  sx,
  children,
  ...props
}: Props) => {
  return (
    <Typography
      sx={{
        padding: 0.5,
        bgcolor: `${color}.main`,
        color: `${color}.contrastText`,
        fontWeight: 'bold',
        borderRadius: 1,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default TextContained;
